import Navbar from './Navbar';
import Home from './Home';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Create from './Create';
import NotFound from './NotFound'
import Contact from './Contact'
import BackendData from './BackendData'
import LineAndSuggestion from './LineAndSuggestion';
import { AuthProvider } from './contexts/AuthContext';
import Login from './Login'; 
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword'; 
import HeartlandAuthRedirect from './HeartlandAuthRedirect';
import HandleHeartlandRedirect from './HandleHeartlandRedirect';
import CreateOrHeartlandAuth from './CreateOrHeartlandAuth';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Navbar /> 
          <div className="content">
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/create-new-query" element={<Create />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/lineandsuggest" element={<LineAndSuggestion />} />
                <Route path="/backendData" element={<BackendData />} />
                <Route path="/heartland-auth-redirect" element={<HeartlandAuthRedirect/>} />
                <Route path="/create" element={<HandleHeartlandRedirect />} />
                <Route path="/newquery" element={<CreateOrHeartlandAuth/>} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;