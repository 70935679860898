import React, { useState } from 'react';
import useFetch from './useFetch';
import { Link, useNavigate } from 'react-router-dom'; 
import { Button, Alert } from 'react-bootstrap'; 
import { useAuth } from './contexts/AuthContext'

const Home = () => {
    //const { data: queries, isLoading, error} = useFetch('http://localhost:8000/queries');
    const [error, setError] = useState(""); 
    const { logout } = useAuth(); 
    const navigate = useNavigate(); 

    async function handleLogout() {
        setError(''); 

        try {
            await logout(); 
            navigate("/login"); 
        } catch {
            setError('Failed to log out')
        }
    }

    return (
        <div className="home">
            <div>
                <h3>Track Sales/Inventory Data:</h3>
                <Link to="/newquery">
                    <button type="button">New Query</button>
                </Link>
            </div>

            <br />

            <div>
                <h3>Request Time Off or Report Incident:</h3>

                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfQu4IhdNIB4RfVsi4b-N4IBUS7c_B8Fp1S1Os2z2_lkWDvAw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                    <button type="button">Time Off Request Form</button>
                </a>

                <a href="https://docs.google.com/forms/d/e/1FAIpQLSegcVEP7Hze_b8ixvwC9H54DgpAHGfgNaYZvrBkghjTvEjREA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                    <button type="button">Incident Report Form</button>
                </a>
            </div>

            <br />

            <div>
                <h3>Links:</h3>

                <a href="https://docs.google.com/document/d/1DT6IbAzV_C438PG4o_DyrWWRmXjihTo5l-z00Qbkrzc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <button type="button">Phishing Awareness</button>
                </a>

                <a href="https://docs.google.com/document/d/153hfxYvTO0uo52JDCmcEWLUhSVdh2Ga62W9ffPExuu8/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <button type="button">Handbook</button>
                </a>

                <a href="https://my.guideline.com/login?_gl=1*15x80el*_ga*ODAyODcyMzQzLjE3MDIwNDI2MjA.*_ga_BS3XXVNTGY*MTcwMjA0MjYyMC4xLjEuMTcwMjA0MjcxNS42MC4wLjA.*_gcl_aw*R0NMLjE3MDIwNDI3MTUuQ2p3S0NBaUFtc3VyQmhCdkVpd0E2ZS1XUEY2N2NLc2lDUXpzSkRmQ2lVclY2XzVPY2o1ckliWnY1dWQybzh5MmFvNXlydE1QWTZVQXRCb0M0NkVRQXZEX0J3RQ..*_gcl_au*MzYwODg0MzkxLjE3MDIwNDI2MjE." target="_blank" rel="noopener noreferrer">
                    <button type="button">401K - Guideline</button>
                </a>

                <a href="https://www.wealthfront.com/login" target="_blank" rel="noopener noreferrer">
                    <button type="button">Wealthfront</button>
                </a>

                <a href="https://anthem.com/" target="_blank" rel="noopener noreferrer">
                    <button type="button">Health Insurance Portal</button>
                </a>

                <a href="https://app.nectarhr.com/" target="_blank" rel="noopener noreferrer">
                    <button type="button">Nectar</button>
                </a>

                <a href="https://mail.google.com/" target="_blank" rel="noopener noreferrer">
                    <button type="button">Gmail</button>
                </a>

                <a href="https://northpointpets.slack.com/" target="_blank" rel="noopener noreferrer">
                    <button type="button">Slack</button>
                </a>

                <a href="https://drive.google.com/drive/folders/1w6_cwVnEXYF38s-_x1O-BSYqV2uotc8C?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <button type="button">WAR Reports</button>
                </a>

                <a href="https://www.signsandshirts.com/northpoint/shop/home" target="_blank" rel="noopener noreferrer">
                    <button type="button">Apparel Store</button>
                </a>

                <a href="https://www.dropbox.com/scl/fi/bjxg7czx1v7uf0eh5b5am/Veterinary-Nutrition-with-Siobhan-Bridglalsingh-March-25th-2024_Recording_1920x1120.mp4?rlkey=80faosfhk6uzgtfq9as62r8c8&dl=0" target="_blank" rel="noopener noreferrer">
                    <button type="button">Veterinary Nutrition Meeting 3/25/2024 Recording</button>
                </a>

                <a href="https://www.vetcalculators.com/calories.html" target="_blank" rel="noopener noreferrer">
                    <button type="button">Calorie Calculator</button>
                </a>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}

            <br />

            <div className="w-100 text-center mt-2">
                <h3>Log Out:</h3>
                <Button variant="link" onClick={handleLogout}>Log Out</Button>
            </div>

            <br />
            <br />

            {/* <h2>Query History:</h2> */}

            {/* { error && <div>{ error }</div> }
            { isLoading && <div>Loading...</div> }
            { queries && <QueryList queries={queries} title="All Queries" /> } */}
        </div>
    );
}
 
export default Home; 